var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { alpha } from '@mui/material/styles';
// ----------------------------------------------------------------------
export default function Autocomplete(theme) {
    return {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& span.MuiAutocomplete-tag': __assign(__assign({}, theme.typography.body2), { width: 24, height: 24, lineHeight: '24px', textAlign: 'center', borderRadius: theme.shape.borderRadius, backgroundColor: alpha(theme.palette.grey[500], 0.16) }),
                },
                paper: {
                    boxShadow: theme.customShadows.dropdown,
                },
                listbox: {
                    padding: theme.spacing(0, 1),
                },
                option: __assign(__assign({}, theme.typography.body2), { padding: theme.spacing(1), margin: theme.spacing(0.75, 0), borderRadius: theme.shape.borderRadius }),
            },
        },
    };
}
